<template>
  <div v-if="!isThemeLoaded">
    <router-view />
    <!-- โหลดหน้าอื่นเมื่อ getTheme เสร็จ -->
  </div>
</template>

<script>
import imgLogo from "@/assets/images/icons/logo.svg";

export default {
  name: "App",

  data() {
    return {
      imgLogo: imgLogo,
      isThemeLoaded: true,
    };
  },
  async created() {
    await this.getCustomerLogin();
    // await this.getAllowTimeChange();
    await this.getDefaultTheme();
    await this.getTheme();
    await this.$moment.locale("th");
  },
  methods: {
    invertColor(hex, bw) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    },
    async getTheme() {
      this.isThemeLoaded = true;
      const theme = await this.axios(`${this.$baseUrl}/customer/theme`);

      this.$store.dispatch("setIsCenterPoint", theme.is_center_point);
      this.$store.dispatch(
        "setLimitSecondaryTelephone",
        theme.limit_secondary_telephone
      );

      this.$store.dispatch("setIsRequirePin", theme.require_pin_login);
      this.$store.dispatch("setTimeLockPin", parseInt(theme.time_lock_pin));

      this.$store.dispatch("setIsVoidCredit", parseInt(theme.void_return_to_store_credit));

      this.$store.dispatch("setColorPrimary", theme.themePrimaryColor);
      this.$store.dispatch("setColorSecondary", theme.themeSecondaryColor);

      this.$store.dispatch(
        "setBackgroundPrimary",
        `background-color: ${theme.themePrimaryColor}`
      );
      // }
      this.$store.dispatch(
        "setBackgroundSecondary",
        `background-color: ${theme.themeSecondaryColor}`
      );
      // console.log( this.$baseTheme);
      this.$store.dispatch(
        "setLoGoHomePage",
        this.$baseTheme + theme.themeLogoHomePage
      );
      // },
      const data = theme;
      let bodyStyles = document.body.style;
      bodyStyles.setProperty("--primary-color", data.themePrimaryColor);
      bodyStyles.setProperty("--secondary-color", data.themeSecondaryColor);
      bodyStyles.setProperty(
        "--font-color",
        this.invertColor(data.themeSecondaryColor, true)
      );
      bodyStyles.setProperty(
        "--font-primary-color",
        this.invertColor(data.themePrimaryColor, true)
      );
      bodyStyles.setProperty("--font-disable-color", data.themeDisableColor);
      bodyStyles.setProperty(
        "--font-disable-color",
        this.invertColor(data.themeDisableColor, true)
      );
      this.isThemeLoaded = false;
    },
    async getDefaultTheme() {
      const theme = {
        themePrimaryColor: "#FFFFFF",
        themeSecondaryColor: "#FFFFFF",
        themeLogoLoginPage: imgLogo,
        themeDisableColor: "#FFFFFF",
      };

      this.$store.dispatch("setColorPrimary", theme.themePrimaryColor);
      this.$store.dispatch("setColorSecondary", theme.themeSecondaryColor);

      this.$store.dispatch(
        "setBackgroundPrimary",
        `background-color: ${theme.themePrimaryColor}`
      );

      // }
      this.$store.dispatch(
        "setBackgroundSecondary",
        `background-color: ${theme.themeSecondaryColor}`
      );
      // console.log( this.$basetheme.Theme);
      this.$store.dispatch("setLoGoHomePage", theme.themeLogoLoginPage);
      // },
      const data = theme;
      let bodyStyles = document.body.style;
      bodyStyles.setProperty("--primary-color", data.themePrimaryColor);
      bodyStyles.setProperty("--secondary-color", data.themeSecondaryColor);
      bodyStyles.setProperty(
        "--font-color",
        this.invertColor(data.themeSecondaryColor, true)
      );
      bodyStyles.setProperty(
        "--font-primary-color",
        this.invertColor(data.themePrimaryColor, true)
      );
      bodyStyles.setProperty("--font-disable-color", data.themeDisableColor);
      bodyStyles.setProperty(
        "--font-disable-color",
        this.invertColor(data.themeDisableColor, true)
      );
    },
    // async getAllowTimeChange() {
    //   let branchId = this.$cookies.get("branch_id");
    //   if(branchId){
    //     await this.axios
    //       .get(
    //         `${this.$baseUrl}/transaction/get_allow_time_change/${branchId}`
    //       )
    //       .then(async (data) => {
    //         if (data.result == 1) {
    //           let result = {
    //             result: data.detail.result,
    //             allowDay: data.detail.allow_day,
    //             allowMonth: data.detail.allow_month
    //           };
    //           await this.$store.dispatch("setAllowTimeChangeDetail", result);
    //         }
    //       });
    //   }
    // },
    async getCustomerLogin() {
      let accountName = this.$cookies.get("account_name");
      await this.$store.dispatch("setAccountName", accountName);

      let plantId = this.$cookies.get("plant_id");
      await this.$store.dispatch("setPlantId", plantId);


      let branchId = this.$cookies.get("branch_id");
      await this.$store.dispatch("setBranchId", parseInt(branchId));

      let branchName = this.$cookies.get("branch_name");
      await this.$store.dispatch("setBranchName", branchName);

      let branchType = this.$cookies.get("branch_type_name");
      await this.$store.dispatch("setBranchType", branchType);

      let spa = this.$cookies.get("spa");
      await this.$store.dispatch("setSpa", spa);

      let booth = this.$cookies.get("booth");
      await this.$store.dispatch("setBooth", booth);

      let isFullPos = this.$cookies.get("is_fullpos");
      await this.$store.dispatch("setIsFullPos", parseInt(isFullPos));

      let allowTimeChange = this.$cookies.get("allow_time_change");
      await this.$store.dispatch("setAllowTimeChange", allowTimeChange);

      let showInventory = this.$cookies.get("booth_show_inventory");
      await this.$store.dispatch("setShowInventory", parseInt(showInventory));

      let allowTimeChangeService = this.$cookies.get(
        "allow_time_change_service"
      );
      await this.$store.dispatch(
        "setAllowTimeChangeService",
        allowTimeChangeService
      );

      let pointSystem = this.$cookies.get("point_system");
      await this.$store.dispatch("setPointSystem", parseInt(pointSystem));

      let invoiceLength = this.$cookies.get("invoice_length");
      await this.$store.dispatch("setInvoiceLength", parseInt(invoiceLength));

      let isInvoiceLeadingZero = this.$cookies.get("is_invoice_leading_zero");
      await this.$store.dispatch(
        "setIsInvoiceLeadingZero",
        parseInt(isInvoiceLeadingZero)
      );

      let userGuid = this.$cookies.get("user_guid");
      await this.$store.dispatch("setUserGuid", userGuid);

      let allowPastCoupon = this.$cookies.get("allow_past_coupon");
      await this.$store.dispatch(
        "setAllowPastCoupon",
        parseInt(allowPastCoupon)
      );
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
